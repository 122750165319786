import request from '../../utils/request'

export default class ToolDateRequest {
  // 导入操作
  async importPost (data) {
    return request.post('/Business/Date/Import', data)
  }

  // 添加操作
  async submitPost (data) {
    return request.post('/Business/Date/Submit', data)
  }
}
